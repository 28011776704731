<template>
  <div id="" class="container">
    <Navbar :event="event" :eventId="eventId"></Navbar>
    <div v-if="event && group" class="d-flex flex-row mt-3">
      <div class="flex-grow-1">
        <Breadcrumbs :event="event" :pages="[unit, group]" :currentPageName="group.name" />
      </div>
      <div class="align-self-end">
        <h3>{{ event.name }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <button type="button" @click="changeGroupName" class="btn btn-primary">Change name</button>
      </div>
      <div class="col-1">
        <button type="button" @click="deleteGroup" class="btn btn-primary">Delete</button>
      </div>
    </div>

    <PeopleData :people="people" :event="event" :selectedUnit="parseInt(unitId)" :selectedGroup="parseInt(groupId)"
                :unit_names="unit_names"
                :group_names="group_names" :profiles="profiles"
                :unitId="unitId"
                :groupId="groupId"
                v-on:addedPeople="addedPeople"
                v-on:updatedProfile="updatedProfile"
                v-on:updatedPerson="updatedPerson"
    />
    <Modal v-bind:show-modal.sync="changingGroupName" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Change group</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="groupName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="close()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="saveGroupName">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import MassAddPeople from "@/components/MassAddPeople";
import UnitGroupPeople from "@/components/UnitGroupPeople";
import {addEventUnitGroup, deleteGroup, deleteUnit, getEvent, getEventGroup, updateGroup, updateUnit} from "@/api";
import PeopleData from "@/components/PeopleData";
import Breadcrumbs from "@/components/Breadcrumbs";
import Modal from "@/components/Modal";

export default {
  name: 'EventGroup',
  props: ['eventId', 'unitId', 'groupId'],
  components: {
    Navbar,
    PeopleData,
    Breadcrumbs,
    Modal
  },
  data () {
    return {
      unit: null,
      event: null,
      group: null,
      people: [],
      addPersonFields: [],

      group_names: [],
      unit_names: [],
      wristbands: [],
      badges: [],
      profiles: [],
      loaded: false,
      groupName: '',
      changingGroupName: false
    }
  },
  methods: {
    addedPeople(added) {
      this.people = [...this.people, ...added]
    },
    changeGroupName() {
      this.changingGroupName = true
      this.groupName = this.group.name
    },
    async deleteGroup() {
      if (confirm("Are you sure you want to delete this group?")) {
        await deleteGroup(this.eventId, this.unitId, this.groupId)
      }
    },
    async saveGroupName() {
      await updateGroup(this.eventId, this.unitId, this.groupId, {name: this.groupName})
      this.group.name = this.groupName
      this.close()
    },
    close() {
      this.changingGroupName = false
    },
    async addGroup() {
      const group = await addEventUnitGroup(this.eventId, this.unitId, {name: this.groupName})
      this.groups.push(group)
      this.groupName = ''
    },
    updatedPerson(personId, data) {
      let person = this.people.find(p => p.id === personId)
      person.name = data.name
      person.comments = data.comments
      person.extra = data.extra
    },
    updatedProfile(profileId, updatedPeople) {
      this.people.forEach(p => {
        if (updatedPeople.includes(p.id)) {
          p.profile_id = profileId
        }
      })
    },
  },
  async mounted() {
    const result = await getEventGroup(this.eventId, this.unitId, this.groupId)

    this.event = result.event
    this.unit = result.unit
    this.group = result.group
    this.people = result.people

    const eventResult = await getEvent(this.eventId)
    this.event = result.event
    this.units = result.units
    this.group_names = eventResult.group_names
    this.unit_names = eventResult.unit_names
    this.wristbands = eventResult.wristbands
    this.badges = eventResult.badges
    this.profiles = eventResult.profiles

    this.loaded = true
  }
}
</script>
