<template>
  <div>
    <Modal v-bind:show-modal.sync="editingPerson" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Change person</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Comments
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personComments"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Extra
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personExtra"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closePersonEditor()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="savePersonChanges">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="addingOnePerson" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Add new person</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Comments
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personComments"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Extra
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personExtra"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closePersonAdding()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="addOnePerson">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="massAddingPeople" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Mass editor</h5>
              <div class="col-auto p-0">
              </div>
            </div>
            <div class="modal-body">
              <textarea class="form-control w-100" rows="10" v-model="massAddNames"></textarea>
              <p>Add each new entry onto a new row. The entry format is: Jane Jones, comment +3 OR Jane Jones +3.</p>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closeMassAdding()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="massAddPeople">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <div v-if="unitId !== null" class="mt-2 row">
      <div class="col-auto">
        <button @click="addNewPerson" type="button" class="btn btn-primary">Add new person</button>
        <button @click="openMassAddPeople" type="button" class="ms-2 btn btn-primary">Mass editor</button>
      </div>
    </div>
    <div v-if="event" class="d-flex flex-row mt-3">
      <div class="d-inline-flex">
        <input class="form-control" type="text" v-model="textFilter" placeholder="Search"/>
      </div>
      <div class="ms-3 d-inline-flex">
        <select v-model="selectedProfile">
          <option value="" disabled>Set profile</option>
          <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">{{ profile.name }}</option>
        </select>
        <button class="ms-2 btn btn-primary" v-on:click="setProfile()">Update</button>
      </div>

      <div class="ms-3 d-inline-flex align-middle">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="noProfile" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            No profile
          </label>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div class="align-self-end">
        <button class="btn btn-primary" v-on:click="changePerson()">
          <Icon name="pen"/>
        </button>
        <button class="ms-2 btn btn-primary" v-on:click="deleteSelected()">
          <Icon name="trash"/>
        </button>
      </div>
    </div>
    <table class="table mt-3">
      <thead>
      <tr>
        <th><input type="checkbox" v-on:click="masterCheckClicked"/></th>
        <th>Name</th>
        <th>Extra</th>
        <th>Unit</th>
        <th>Group</th>
        <th>Comments</th>
        <th>Profile</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="person in selectedPersons" v-bind:key="person.id">
        <td><input type="checkbox" :value="person.id" v-model="checkedPeople"/></td>
        <td>{{ person.name }}</td>
        <td>{{ person.guest_count > 1 ? person.guest_count -1 : ''}}</td>
        <td>{{ unit_names[person.unit_id] }}</td>
        <td>{{ person.group_id != null ? group_names[person.group_id] : '' }}</td>
        <td>{{ person.comments }}</td>
        <td>{{ profileName(person.profile_id) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  addEventMassGuests,
  addGroupPerson,
  addUnitPerson,
  deletePeople,
  setProfileForPeople,
  updatePerson
} from "@/api";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";

export default {
  name: "PeopleData",
  props: ['event', 'people', 'profiles', 'selectedUnit', 'selectedGroup', 'unit_names', 'group_names',
    'groupId', 'unitId'],
  components: {Icon, Modal},
  data() {
    return {
      noProfile: false,
      selectedProfile: null,
      checkedPeople: [],
      textFilter: '',
      editingPerson: false,
      addingOnePerson: false,
      massAddingPeople: false,
      personComments: '',
      personName: '',
      massAddNames: '',
      personExtra: 0,
      editedPerson: null
    }
  },
  methods: {
    profileName(id) {
      if (id === null)
        return ''
      let profile = this.profiles.find(p => {
        return p.id === id
      })
      if (profile === undefined)
        return ''

      return profile.name
    },
    addNewPerson() {
      this.addingOnePerson = true
    },
    openMassAddPeople() {
      this.massAddingPeople = true
    },
    async massAddPeople() {
      const addedPeople = await addEventMassGuests(this.event.id, this.unitId, this.groupId, this.massAddNames)
      this.$emit("addedPeople", addedPeople)
      this.massAddNames = ''
      this.massAddingPeople = false
    },
    changePerson() {
      if (this.checkedPeople.length > 1) {
        alert("You have selected too many people")
        return;
      }
      this.editedPerson = this.people.find(p => p.id === this.checkedPeople[0])
      this.personName = this.editedPerson.name
      this.personComments = this.editedPerson.comments
      this.personExtra = this.editedPerson.guest_count -1
      this.editingPerson = true
    },
    async savePersonChanges() {
      await updatePerson(this.event.id, this.editedPerson.id, {
        'name': this.personName,
        'comments': this.personComments,
        'extra': this.personExtra
      })
      this.$emit('updatedPerson', this.editedPerson.id, {
        'name': this.personName,
        'comments': this.personComments,
        'guest_count': this.personExtra + 1
      })
      this.editedPerson = null
      this.editingPerson = false
    },
    async addOnePerson() {
      if (this.groupId) {
        let addedPerson = await addGroupPerson(this.event.id, this.unitId, this.groupId, {
          name: this.personName,
          comments: this.personComments,
          extra: this.personExtra
        })
        this.$emit('addedPeople', [addedPerson])
      } else {
        let addedPerson = await addUnitPerson(this.event.id, this.unitId,
          {
            name: this.personName,
            comments: this.personComments,
            extra: this.personExtra
          })
        this.$emit('addedPeople', [addedPerson])
      }
      this.addingOnePerson = false
      this.personName = ''
      this.personComments = ''
      this.personExtra = ''
    },
    deleteSelected() {
      if (confirm("Are you sure you want to delete the selected people from the event?")) {
        deletePeople(this.eventId, {toDelete: this.checkedPeople})
        this.people = this.people.filter(p => !this.checkedPeople.includes(p.id))
      }
    },
    filterPersonGroupUnit(p) {
      if (this.selectedUnit === null && this.selectedGroup === null) {
        return true
      } else {
        if (p.unit_id !== null && p.unit_id === this.selectedUnit && this.selectedGroup === null)
          return true
        else if (this.selectedGroup !== null && p.group_id === this.selectedGroup) {
          return true
        }
      }
    },
    filterText(p) {
      if (p.comments && p.comments.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      }

      if (p.name.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
    filterNoProfile(p) {
      if (this.noProfile) {
        if (p.profile_id === null) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    closePersonEditor() {
      this.editingPerson = false
      this.editedPerson = null
    },
    closePersonAdding() {
      this.addingOnePerson = false
    },
    closeMassAdding() {
      this.massAddingPeople = false
    },
    setProfile() {
      setProfileForPeople(this.eventId, this.selectedProfile, this.checkedPeople)
      this.$emit('updatedProfile', this.selectedProfile, this.checkedPeople)
    },
    masterCheckClicked(event) {
      if (event.target.checked) {
        this.checkedPeople = this.selectedPersons.map(p => p.id)
      } else {
        this.checkedPeople = []
      }
    },
  },
  computed: {
    selectedPersons: function () {
      return this.people.filter(p => {
        return this.filterPersonGroupUnit(p) && this.filterText(p) && this.filterNoProfile(p)
      })
    },
  },
}
</script>
