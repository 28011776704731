<template>
  <div id="" class="container">
    <Navbar :event="event" :eventId="id"></Navbar>
    <h2>Event dashboard tuleb siia</h2>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import {getEvent} from "@/api";

export default {
  name: 'Event',
  props: ['id'],
  components: {
    Navbar,
  },
  data () {
    return {
      event: null,
    }
  },
  async mounted() {
    const result = await getEvent(this.id)
    this.event = result.event
  },
}
</script>
