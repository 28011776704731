<template>
  <div class="container">
    <Navbar></Navbar>
    <div><h4>Events</h4>
      <div v-for="event in events" v-bind:key="event.id">
        <EventItem :event="event" />
      </div>
    </div>

    <div><h4>Units</h4>
      <div v-for="unit in units" v-bind:key="unit.id">
        <router-link :to="{name: 'EventUnit', params: { eventId: unit.event_id, unitId: unit.id } }" class="nav-top-link">
          {{unit.name}}
        </router-link>
      </div>
    </div>

    <div>
      <h4>Groups</h4>
      <ul class="list-group">
        <li class="list-group-item" v-for="g in groups" v-bind:key="g.id">
          <router-link :to="{name: 'EventGroup', params: { eventId: g.event_id, unitId: g.unit_id, groupId: g.id } }">
            {{g.name}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import EventItem from "@/components/EventItem";

export default {
  name: 'UserDashboard',
  components: {
    EventItem,
    Navbar
  },
  data () {
    return {
      events: [],
      groups: [],
      units: []
    }
  },
  async mounted() {
    const headers = {
      "Authorization": "Bearer " + store.jwt()
    }

    const result = await axios.get("/api/user_dashboard")
    this.events = result.data.events
    this.groups = result.data.groups
    this.units = result.data.units
  }
}
</script>
