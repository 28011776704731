<template>
  <div class="container">
    <template v-if="accessSystem !== null">
      <Navbar :event="event" :eventId="eventId"></Navbar>
      <AccessSystemDisplay v-if="accessSystem.saved" :accessSystem="accessSystem" :event="event" />
      <AccessSystemEditing v-else :accessSystem="accessSystem" :eventId="eventId" />
    </template>
  </div>
</template>
<script>

import Navbar from '@/components/Navbar.vue'
import AccessSystemEditing from "@/components/AccessSystemEditing";
import AccessSystemDisplay from "@/components/AccessSystemDisplay";
import {getAccessSystem} from "@/api";

export default {
  name: 'AccessSystem',
  props: ['eventId'],
  components: {
    AccessSystemEditing,
    AccessSystemDisplay,
    Navbar,
  },
  data() {
    return {
      event: null,
      accessSystem: null,
    }
  },
  async mounted() {
    this.accessSystem = await getAccessSystem(this.eventId)
    this.event = this.accessSystem.event
  }
}
</script>
