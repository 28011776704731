<template>
  <div>
    <ul class="mt-2 list-group" v-for="unit in units" v-bind:key="unit.id">
      <button @click="selectUnit(unit.id)"
              v-bind:class="[selectedUnit === unit.id ? 'btn-primary' : 'btn-outline-primary', 'btn btn-sm']">
        {{ unit.name }}
      </button>
      <span v-if="selectedUnit === unit.id">
      <li class="list-group-item">
        <ul class="mt-2 list-group" v-if="unit.groups.length > 0">
          <li @click="selectGroup(group.id)" v-for="group in unit.groups" v-bind:key="group.id"
              v-bind:class="[selectedGroup === group.id ? 'active' : '', 'list-group-item']">
          {{ group.name }}
          </li>
        </ul>
      </li>
    </span>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FilterUnitGroup",
  props: ['units'],
  data() {
    return {
      selectedGroup: null,
      selectedUnit: null
    }
  },
  methods: {
    selectGroup(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
      this.$emit('selectedGroup', group)
    },
    selectUnit(unit) {
      if (this.selectedUnit === unit) {
        this.selectedUnit = null
      } else {
        this.selectedUnit = unit
        this.selectedGroup = null
      }
      this.$emit('selectedUnit', unit)
    },
  },
}
</script>


