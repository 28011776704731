import store from './store'

function get(path) {
   return callApi('GET', path, null)
}

function post(path, data) {
  return callApi('POST', path, JSON.stringify(data))
}

async function callApi(method, path, body) {
  const headers = new Headers({})
  headers.set('Authorization', "Bearer " + store.jwt())
  if (method === 'POST') {
    headers.set('Content-Type', 'application/json')
  }

  const response = await fetch(path,{method, headers, body: body})

  return await response.json()
}

export function getEvents() {
  return get("/api/events")
}

export function getEvent(eventId) {
  return get(`/api/events/${eventId}`)
}

export function getEventPeople(eventId) {
  return get(`/api/event/${eventId}/people`)
}

export function checkInPerson(eventId, body) {
  return post(`/api/event/${eventId}/check_in`, body)
}

export function getEventUsers(eventId) {
  return get(`/api/event/${eventId}/users`)
}

export function addGroupPerson(eventId, unitId, groupId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/group/${groupId}/add_person`, data)
}

export function addUnitPerson(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_person`, data)
}

export function getAccessSystem(eventId) {
  return get(`/api/event/${eventId}/access_system`)
}

export function saveAccessSystem(eventId, accessSystem) {
  return post(`/api/event/${eventId}/save_access`, accessSystem)
}

export function startEditingMatrix(eventId) {
  return post(`/api/event/${eventId}/start_edit_access`)
}

export function addEventProfile(eventId, data) {
  return post(`/api/event/${eventId}/add_profile`, data)
}

export function addEventWristband(eventId, data) {
  return post(`/api/event/${eventId}/add_wristband`, data)
}

export function addEventBadge(eventId, data) {
  return post(`/api/event/${eventId}/add_badge`, data)
}

export function addEventTime(eventId, data) {
  return post(`/api/event/${eventId}/add_time`, data)
}

export function addEventArea(eventId, data) {
  return post(`/api/event/${eventId}/add_area`, data)
}

export function removeCheckIn(eventId, data) {
  return post(`/api/event/${eventId}/remove_check_in`, data)
}

export function checkInAllGuests(eventId, data) {
  return post(`/api/event/${eventId}/check_in_all`, data)
}

export function removeEventPerson(eventId, data) {
  return post(`/api/events/${eventId}/delete_person`, data)
}

export function updatePerson(eventId, personId, data) {
  return post(`/api/events/${eventId}/update_person/${personId}`, data)
}

export function approvePerson(eventId, personId) {
  return post(`/api/events/${eventId}/approve_person/${personId}`)
}

export function removePersonApproval(eventId, personId) {
  return post(`/api/events/${eventId}/remove_approval/${person.id}`)
}

export function updateEventObject(eventId, objectType, objectId, data) {
  return post(`/api/event/${eventId}/${objectType}/${objectId}`, data)
}

export function deleteEventObject(eventId, objectType, objectId) {
  return post(`/api/event/${eventId}/${objectType}/${objectId}/delete`)
}

export function addEventUnit(eventId, data) {
  return post(`/api/event/${eventId}/units`, data)
}

export function getEventUnits(eventId) {
  return get(`/api/event/${eventId}/units`)
}

export function addEventUnitGroup(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}`, data)
}

export function addEventUser(eventId, data) {
  return post(`/api/event/${eventId}/add_user`, data)
}

export function addEventGroup(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_group`, data)
}

export function eventUnitAddManager(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_manager`, data)
}

export function eventUnitAddPerson(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/add_person`, data)
}

export function getEventUnit(eventId, unitId) {
  return get(`/api/event/${eventId}/unit/${unitId}`)
}

export function getEventGroup(eventId, unitId, groupId) {
  return get(`/api/event/${eventId}/unit/${unitId}/group/${groupId}`)
}

export function deletePeople(eventId, data) {
  return post(`/api/event/${eventId}/delete_people`, data)
}

export function setProfileForPeople(eventId, profileId, data) {
  return post(`/api/event/${eventId}/set_profile_for_people`, {'profileId': profileId, 'people': data})
}

export function addEventMassGuests(eventId, unitId, groupId, guests) {
  return post(`/api/event/${eventId}/add_guests`, {
    'guests': guests,
    'unit_id': unitId,
    'group_id': groupId,
  })
}

export function deleteUnit(eventId, unitId) {
  return post(`/api/event/${eventId}/unit/${unitId}/delete`, {})
}

export function updateUnit(eventId, unitId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/update`, data)
}

export function deleteGroup(eventId, unitId, groupId) {
  return post(`/api/event/${eventId}/unit/${unitId}/group/${groupId}/delete`, {})
}

export function updateGroup(eventId, unitId, groupId, data) {
  return post(`/api/event/${eventId}/unit/${unitId}/group/${groupId}/update`, data)
}

