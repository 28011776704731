<template>
  <div id="" class="container check-in">
    <Navbar :event="event" :eventId="eventId"/>
    <div v-if="event" class="row">
      <div id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <h4 class="mt-3">People</h4>
        <p>Total: {{ totalPeople }}<br/>
          Checked in: {{ totalPeopleCheckedIn }}<br/>
          Not checked in: {{ totalPeople - totalPeopleCheckedIn }}</p>

        <span v-if="selectedUnit">
            In this unit:<br/>
            total: {{ selectedUnitTotal }}<br/>
            checked-in: {{ selectedUnitCheckedIn }}<br/>
            not checked in: {{ selectedUnitTotal - selectedUnitCheckedIn }}
          </span>
        <div>
          <FilterUnitGroup v-on:selectedUnit="selectUnit" v-on:selectedGroup="selectGroup" :units="units"/>
        </div>
      </div>
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div class="d-flex flex-row mt-3">
          <div class="flex-grow-1">
            <Breadcrumbs :event="event" currentPageName="Check in"/>
          </div>
          <div class="align-self-end">
            <h3>{{ event.name }}</h3>
          </div>
        </div>
        <div>
          <h2>Check in</h2>
        </div>
        <div v-if="event !== null">
          <div class="d-flex flex-row bd-highlight mb-3">
            <div class="p-2 flex-grow-1">
              <input type="text" v-model="textFilter" placeholder="Search"/>
            </div>
            <div class="p-2">
              <button @click="toggleCheckedIn(false)"
                      v-bind:class="[checkedInFilter === false ? 'btn-primary' : 'btn-outline-primary', 'btn', 'btn-sm', 'me-1']">
                Not checked in
              </button>
              <button @click="toggleCheckedIn(true)"
                      v-bind:class="[checkedInFilter === true ? 'btn-primary' : 'btn-outline-primary', 'btn', 'btn-sm', 'me-1']">
                Checked in
              </button>
              <button type="button" @click="toggleCheckedIn(null)"
                      v-bind:class="[checkedInFilter === null ? 'btn-primary' : 'btn-outline-primary', 'btn btn-sm']">
                All
              </button>
            </div>
          </div>
          <div class="row">
            <div clasS="col-12">
              <PersonCheckInItem v-for="person in selectedPersons" v-bind:key="person.id"
                                 :person="person" :unit_names="unit_names" :group_names="group_names"
                                 :wristbands="wristbands" :badges="badges"
                                 :eventId="eventId" v-on:opened="openedItem" :openedItemId="openedPerson"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import PersonCheckInItem from "@/components/PersonCheckInItem";
import store from "@/store";
import axios from "axios";
import {getEvent, getEventPeople} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import FilterUnitGroup from "@/components/FilterUnitGroup";

export default {
  name: "CheckIn",
  components: {FilterUnitGroup, Breadcrumbs, Navbar, PersonCheckInItem},
  props: ['eventId'],
  data() {
    return {
      event: null,
      people: [],
      fields: [],
      units: [],
      unit_names: [],
      group_names: [],
      selectedPerson: null,
      selectedGroup: null,
      selectedUnit: null,
      wristbands: [],
      badges: [],
      textFilter: "",
      checkedInFilter: false,
      openedPerson: null
    }
  },
  async mounted() {
    const people = await getEventPeople(this.eventId)
    this.people = people.people
    this.fields = people.fields

    const result = await getEvent(this.eventId)
    this.units = result.units
    this.group_names = result.group_names
    this.unit_names = result.unit_names
    this.wristbands = result.wristbands
    this.badges = result.badges

    this.event = result.event
  },
  methods: {
    selectGroup(group) {
      console.log(group)
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
    },
    selectUnit(unit) {
      console.log(unit)
      if (this.selectedUnit === unit) {
        this.selectedUnit = null
      } else {
        this.selectedUnit = unit
        this.selectedGroup = null
      }
    },
    selectAll() {
      this.selectedGroup = null
      this.selectedUnit = null
    },
    filterText(p) {
      if (p.comments && p.comments.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      }

      if (p.name.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
    filterCheckedIn(p) {
      if (this.checkedInFilter === null)
        return true
      if (this.checkedInFilter === false && (p.guest_count !== p.checked_in_count))
        return true
      else if (this.checkedInFilter === true && p.checked_in_count === p.guest_count) {
        return true
      }
      return false
    },
    filterPersonGroupUnit(p) {
      if (this.selectedUnit === null && this.selectedGroup === null) {
        return true
      } else {
        if (p.unit_id !== null && p.unit_id === this.selectedUnit && this.selectedGroup === null)
          return true
        else if (this.selectedGroup !== null && p.group_id === this.selectedGroup) {
          return true
        }
      }
    },
    toggleCheckedIn(value) {
      this.checkedInFilter = value
    },
    openedItem: function (personId) {
      this.openedPerson = personId
    }
  },
  computed: {
    selectedPersons: function () {
      return this.people.filter(p => {
        return this.filterPersonGroupUnit(p) && this.filterText(p) && this.filterCheckedIn(p)
      })
    },
    selectedUnitPeople: function () {
      return this.people.filter(p => {
        return this.selectedUnit != null && p.unit_id === this.selectedUnit
      })
    },
    totalPeople: function () {
      return this.people.map(p => p.guest_count).reduce((total, p) => total + p, 0)
    },
    totalPeopleCheckedIn: function () {
      return this.people.map(p => p.checked_in_count).reduce((total, p) => total + p, 0)
    },
    selectedUnitTotal: function () {
      return this.selectedUnitPeople.map(p => p.guest_count).reduce((total, p) => total + p, 0)
    },
    selectedUnitCheckedIn: function () {
      return this.selectedUnitPeople.map(p => p.checked_in_count).reduce((total, p) => total + p, 0)
    }
  },
}
</script>

