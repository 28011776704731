<template>
  <div>
    <div class="mt-3">
      <h2 class="float-start">Access matrix</h2>
      <button v-on:click="editMatrix()" class="float-end btn btn-secondary">Edit matrix</button>
    </div>
    <table class="table mt-3">
      <tbody>
      <tr>
        <td>
        </td>
        <td class="text-center" v-for="area in accessSystem.areas" :key="area.id">{{ area.name }}</td>
        <td class="text-center">Times</td>
        <td class="text-center">Wristband</td>
        <td class="text-center">Badge</td>
      </tr>

      <tr v-for="profile in accessSystem.profiles" :key="profile.id">
        <td class="text-center">{{ profile.name }}</td>
        <td :class="selectedItemClasses(profile.areas.includes(area.id))" v-for="area in accessSystem.areas"
            :key="area.id">
          <span v-if="profile.areas.includes(area.id)"><Check/></span>
        </td>
        <td class="text-center bg-light">
          {{ getName(profile.timeId, accessSystem.times) }}
        </td>
        <td class="text-center bg-light">
          {{ getName(profile.wristbandId, accessSystem.wristbands) }}
        </td>
        <td class="text-center bg-light">
          {{ getName(profile.badgeId, accessSystem.badges) }}
        </td>
      </tr>
      </tbody>
    </table>

    <h2>Wristbands</h2>
    <div class="card mt-2" v-for="wristband in accessSystem.wristbands" v-bind:key="wristband.id">
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <div class="item-title">
              Name
            </div>
            <div>
              {{ wristband.name }}
            </div>
          </div>
          <div class="col-10">
            <img class="img-fluid" :src="wristband.picture_url"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Check from "@/components/icons/Check";
import {startEditingMatrix} from "@/api";

export default {
  name: 'AccessSystemEditing',
  components: {Check},
  props: [
    'event',
    'accessSystem'
  ],
  methods: {
    getName(id, list) {
      const items = list.filter(f => f.id === id)
      if (items.length > 0) {
        return items[0].name
      }

      return ''
    },
    async editMatrix() {
      await startEditingMatrix(this.accessSystem.event.id)
      this.$router.go()
    },
    selectedItemClasses(selected) {
      return {
        'text-center': true,
        'bg-dark': selected === true,
        'bg-light': selected === false,
        'text-light': selected === true
      }
    }
  },
}
</script>
