<template>
  <div class="container">
    <Navbar :event="event" :eventId="eventId"></Navbar>
    <div class="pt-3 mb-4">Event users</div>
    <div class="card elevation-16 mb-3">
      <div class="card-body">
      <form v-on:submit.prevent="addUser">
          <div class="row">
            <div class="col-12 col-md-6">
              <label class="form-label">E-mail
              <input class="form-control" v-model="email" type="text" />
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <label class="form-label">Role ('check-in', 'manager')
                <input class="form-control" v-model="role" type="text" />
              </label>
            </div>
          </div>
          <div class="mt-1">
            <input type="submit" value="Add user" />
          </div>
        </form>
      </div>
    </div>
    <div class="card" v-for="user in users" v-bind:key="user.id">
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <div class="item-title">Name</div>
            <div>{{user.name}}</div>
          </div>
          <div class="col-2">
            <div class="item-title">E-mail</div>
            <div>{{user.email}}</div>
          </div>
          <div class="col-2">
            <div class="item-title">Role</div>
            <div>{{user.role}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import store from "@/store";
import {addEventUser, getEvent, getEventUsers} from "@/api";

export default {
  name: 'AddUser',
  components: {Navbar},
  props: ['eventId'],
  data () {
    return {
      email: '',
      role: '',
      event: null,
      users: []
    }
  },
  methods: {
    async addUser() {
      await addEventUser(this.eventId, {email: this.email, role: this.role})
    }
  },
  async mounted() {
    this.users = await getEventUsers(this.eventId)
    const result = await getEvent(this.eventId)
    this.event = result.event
  }
}
</script>
