<template>
  <div id="" class="container">
    <Navbar></Navbar>
    <template v-if="loaded">
      <div class="row">
        <div class="col-6">
          <h5>Your events</h5>
          <table class="table">
            <tr v-for="event of events" v-bind:key="event.id">
              <td>
                <router-link :to="{name: 'Event', params: { id: event.id }}" class="nav-top-link">
                  {{event.name}}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h5>Units</h5>
          <table class="table">
            <tr v-for="unit of units" v-bind:key="unit.id">
              <td>
                <router-link :to="{name: 'EventUnit', params: { eventId: unit.event_id, unitId: unit.id }}" class="nav-top-link">
                  {{unit.name}}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h5>Groups</h5>
          <table class="table">
            <tr v-for="group of groups" v-bind:key="group.id">
              <td>
                <router-link :to="{name: 'EventGroup', params: { eventId: group.event_id, unitId: group.uintId, groupId: group.id }}" class="nav-top-link">
                  {{group.name}}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'UserHome',
  props: [],
  components: {
    Navbar,
  },
  data () {
    return {
      units: [],
      groups: [],
      events: [],
      loaded: false,
      headers: {
        "Authorization": "Bearer " + store.jwt()
      }
    }
  },
  methods: {

  },
  async mounted() {
    const result = await axios.get("/api/user_dashboard", { headers: this.headers })
    this.events = result.data.events
    this.groups = result.data.groups
    this.units = result.data.units
    this.loaded = true
  }
}
</script>
