<template>
  <Modal v-bind:show-modal.sync="isShown" :modal-class="'modal-full-screen modal-dialog-centered'">
    <div class="modal-content">
      <div class="modal-body">
        <div v-if="object">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Change {{object.name}}</h5>
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="dismiss()">
                Close
              </button>
            </div>
            <div class="modal-body">
              <div v-if="editing">
                <input class="form-control" type="text" v-model="object.name"/>
                <br/>
                <button @click="saveChanges()" type="button" class="btn btn-primary">Save</button>
                <button @click="deleteObject()" v-if="object.can_delete" type="button" class="mx-3 btn btn-danger">Delete</button>
              </div>
              <div v-else>
                <div class="mr-2">Name:</div>
                <span>{{ object.name }}</span>
              </div>
            </div>
            <div>
              <input type="file" accept="image/*" @change="onImageChange()"/>
            </div>
            <div class="mx-3 mb-3">
              <button type="button" class="btn btn-secondary" @click="toggleEditing()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-pencil"
                     viewBox="0 0 16 16">
                  <path
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import store from "@/store";
import axios from "axios";
import {deleteEventObject, updateEventObject} from "@/api";

export default {
  name: "Modalobject",
  components: {Modal},
  props: ['addPicture'],
  data() {
    return {
      isShown: false,
      editing: false,
      objetType: 'object',
      object: null
    }
  },
  async mounted() {

  },
  methods: {
    present(object, objectType) {
      this.object = object
      this.objectType = objectType
      this.isShown = true
    },
    dismiss() {
      this.isShown = false
      this.editing = false
    },
    toggleEditing() {
      this.editing = !this.editing
    },
    async saveChanges() {
      await updateEventObject(this.eventId, this.objectType, this.object.id, {name: this.object.name})

      this.dismiss()
    },
    async deleteObject() {
      await deleteEventObject(this.eventId, this.objectType, this.object.id)

      this.$emit('deleted', this.object, this.objectType)
    }
  }
}
</script>
