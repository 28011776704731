<template>
  <portal to="modals-container">
    <div class="modal-backdrop fade show" v-show="showModal"></div>
    <div class="modal"
         :class="{ show: showModal }"
         v-show="showModal"
         v-bind:style="{
          display: 'block',
          zIndex: nested ? 1100 : 1050
        }"
         @click="close()">
      <div class="modal-dialog"
           @click.stop
           role="document"
           v-bind:class="[ modalClass ]">

        <slot></slot>
          </div>
        </div>
  </portal>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showModal: Boolean,
    modalClass: String,
    nested: Boolean,
    nonDismissible: Boolean
  },
  methods: {
    close() {
      if (this.nonDismissible) return
      this.$emit('done')
    },
    keyup(e) {
      if (this.showModal && e.keyCode === 27) this.close()
    }
  },
  mounted () {
    document.addEventListener('keyup', this.keyup)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.keyup)
  },
  watch: {
    showModal () {
      document.body.classList[this.showModal ? 'add' : 'remove']('modal-open')
    }
  }
}
</script>
