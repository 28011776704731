<template>
  <div id="" class="container">
    <Navbar :event="event" :eventId="eventId"/>

    <Modal v-bind:show-modal.sync="changingUnitName" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Change unit</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="unitName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="close()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="saveUnitName">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <div v-if="event && unit" class="d-flex flex-row mt-3">
      <div class="flex-grow-1">
        <Breadcrumbs :event="event" :currentPageName="unit.name" />
      </div>
      <div class="align-self-end">
        <h3>{{ event.name }}</h3>
      </div>
    </div>
    <div v-if="loaded">
      <h4>Unit: {{ unit.name }}</h4>
      <div class="row">
        <div class="col-2">
          <button type="button" @click="changeUnitName" class="btn btn-primary">Change name</button>
        </div>
        <div class="col-1">
          <button type="button" @click="deleteUnit" class="btn btn-primary">Delete</button>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="item-title">
            Add group
          </div>
          <div class="row">
            <div class="col-auto">
              <input type="text" class="form-control pe-0" v-model="groupName"/>
            </div>
            <div class="col-auto p-0">
              <button class="btn btn-primary btn-small" @click="addGroup">Add group</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <ul class="mt-2 list-group">
            <li @click="selectGroup(group.id)" class="list-group-item"
              v-for="group in groups" v-bind:key="group.id">
              {{ group.name }}
              <span v-if="selectedGroup === group.id">
                <router-link
                  :to="{name: 'EventGroup', params: { eventId: event.id, unitId: unit.id, groupId: group.id }}"
                  class="nav-link">
                  Edit group
                </router-link>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <PeopleData :people="people" :event="event" :selectedUnit="parseInt(unitId)" :selectedGroup="selectedGroup"
                  :unit_names="unit_names"
                  :group_names="group_names" :profiles="profiles"
                  :unitId="unitId"
                  v-on:addedPeople="addedPeople"
                  v-on:updatedProfile="updatedProfile"
                  v-on:updatedPerson="updatedPerson"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import MassAddPeople from "@/components/MassAddPeople";
import UnitGroupPeople from "@/components/UnitGroupPeople";
import {
  addEventGroup,
  deleteUnit,
  eventUnitAddManager,
  eventUnitAddPerson,
  getEvent,
  getEventUnit,
  updateUnit
} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import PeopleData from "@/components/PeopleData";
import Modal from "@/components/Modal";

export default {
  name: 'EventUnit',
  props: ['eventId', 'unitId'],
  components: {
    Navbar,
    Breadcrumbs,
    PeopleData,
    Modal
  },
  data() {
    return {
      unit: null,
      changingUnitName: false,
      unitName: '',
      personName: '',
      formFields: [],
      event: null,
      groupName: '',
      groups: [],
      managerEMail: '',
      selectedGroup: null,
      people: [],
      addPersonFields: [],
      group_names: [],
      unit_names: [],
      wristbands: [],
      badges: [],
      profiles: [],
      loaded: false,
    }
  },
  methods: {
    addedPeople(added) {
      this.people = [...this.people, ...added]
    },
    updatedProfile(profileId, updatedPeople) {
      this.people.forEach(p => {
        if (updatedPeople.includes(p.id)) {
          p.profile_id = profileId
        }
      })
    },
    close() {
      this.changingUnitName = false
    },
    changeUnitName() {
      this.changingUnitName = true
      this.unitName = this.unit.name
    },
    async saveUnitName() {
      await updateUnit(this.eventId, this.unitId, {name: this.unitName})
      this.unit.name = this.unitName
      this.close()
    },
    async deleteUnit() {
      if (confirm("Are you sure you want to delete the unit?")) {
        await deleteUnit(this.eventId, this.unitId)
      }
    },
    updatedPerson(personId, data) {
      let person = this.people.find(p => p.id === personId)
      person.name = data.name
      person.comments = data.comments
      person.guest_count = data.guest_count
    },
    async addGroup() {
      const group = await addEventGroup(this.eventId, this.unitId, {name: this.groupName})
      this.groups.push(group)
      this.groupName = ''
    },
    async addManager() {
      await eventUnitAddManager(this.eventId, this.unitId, {e_mail: this.managerEMail})
    },
    selectGroup(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
      this.$emit('selectedGroup', group)
    },
  },
  async mounted() {
    const result = await getEventUnit(this.eventId, this.unitId)
    this.event = result.event
    this.groups = result.groups
    this.unit = result.unit
    this.people = result.people
    this.formFields = result.form_fields
    this.loaded = true

    const eventResult = await getEvent(this.eventId)
    this.event = result.event
    this.units = result.units
    this.group_names = eventResult.group_names
    this.unit_names = eventResult.unit_names
    this.wristbands = eventResult.wristbands
    this.badges = eventResult.badges
    this.profiles = eventResult.profiles
  }
}
</script>
