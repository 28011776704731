<template>
  <div class="container">
    <Navbar></Navbar>
    <router-link :to="{name: 'AddEvent' }" class="nav-top-link">
      <span>Add event</span>
    </router-link>
    <EventItem v-for="event in events" :event="event" v-bind:key="event.id"/>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import EventItem from '@/components/EventItem.vue'
import {getEvents} from "@/api";

export default {
  name: 'Events',
  components: {
    Navbar,
    EventItem
  },
  data () {
    return {
      events: []
    }
  },
  async mounted() {
    //const headers = {
    //  "Authorization": "Bearer " + store.jwt()
    //}

    // const result = await axios.get("/api/events", { headers })

    const result = getEvents().then(result => this.events = result)
    //this.events = result
  }
}
</script>
