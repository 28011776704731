<template>
<nav class="navbar navbar-expand navbar-light bg-primary px-2">
  <router-link :to="{name: 'Home'}" class="navbar-brand" href="#">
    Akre
  </router-link>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <router-link :to="{name: 'Events'}" class="nav-link" href="#">
          <span class="nav-text">Home</span>
        </router-link>
      </li>
      <li v-if="event" class="nav-item">
        <router-link  :to="{name: 'Event', params: { id: event.id }}" class="nav-link">
          {{event.name}}
        </router-link>
      </li>
      <li v-if="event" class="nav-item">
        <router-link :to="{name: 'AccessSystem', params: { eventId: event.id }}" class="nav-link" href="#">
          Access system
        </router-link>
      </li>
      <li v-if="event" class="nav-item">
        <router-link :to="{name: 'EventPeople', params: { eventId: event.id }}" class="nav-link" href="#">
          People
        </router-link>
      </li>
      <li v-if="event" class="nav-item">
        <router-link :to="{name: 'CheckIn', params: { eventId: event.id }}" class="nav-link" href="#">
          Check in
        </router-link>
      </li>
      <li v-if="event" class="nav-item">
        <router-link :to="{name: 'EventUsers', params: { eventId: event.id }}" class="nav-link" href="#">
          Users
        </router-link>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav">
    <li class="nav-item"><a class="nav-link">My account</a></li>
    <li class="nav-item"><a class="nav-link" @click.prevent="logout()">Log Out</a></li>
  </ul>
</nav>
</template>
<script>
import axios from 'axios'
import store from '../store'

export default {
  name: 'Navbar',
  props: ['event', 'eventId'],
  methods: {
    async logout() {
      store.logOut()
      const result = await axios.post("/api/logout")
      this.$router.push({name: 'Home'})
    }
  },
}
</script>
