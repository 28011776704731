<template>
  <div v-bind:class="[person.checked_in_count === person.guest_count ? '' : '', 'card mt-3 card-body']">
    <div class="row" v-on:click="openDetails">
      <div class="col-3">
        <div class="item-title">
          Name
        </div>
        <div>
          {{person.name}}
        </div>
      </div>
      <div class="col-2">
        <div class="item-title">
        Extra
        </div>
        <div v-if="person.guest_count > 1" class="hele">+{{ person.plus_ones.count }}</div>
      </div>
      <div class="col-2">
        <div class="item-title">Unit</div>
        <div>{{unit_names[person.unit_id]}}</div>
      </div>
      <div class="col-2">
        <div class="item-title">Wristband</div>
        <div v-if="person.wristband_id && wristbands.length > 0">{{ findWristbandName(person.wristband_id) }}</div>
      </div>
    </div>
    <div class="row mt-1" v-if="openedItemId === this.person.id">
      <div class="col-3">
        <div class="item-title">Comment</div>
        <div>
        {{ person.comments }}
        </div>
      </div>
      <div class="col-2">
        <div class="item-title">Checked in</div>
        <div>{{ person.checked_in_count}}/{{ person.guest_count }}</div>
      </div>
      <div class="col-2">
        <div class="item-title">Group</div>
        <div>{{person.group_id != null ? group_names[person.group_id] : ''}}</div>
      </div>
      <div class="col-2" v-if="person.badge_id && badges.length > 0">
        <div class="item-title">
          Badge
        </div>
        <div>
          {{ findBadgeName(person.badge_id) }}
        </div>
      </div>
      <div class="col-2">
        <div class="float-left" v-if="person.checked_in_count < person.guest_count">
          <span v-if="person.guest_count > 1">
            <button @click="checkIn()" type="button" class="btn btn-secondary btn-sm me-1">Check in 1</button>
            <button @click="checkInAll()" type="button" class="btn btn-secondary btn-sm">All in</button>
          </span>
          <span v-else>
            <button type="button" @click="checkIn()" class="btn btn-secondary">Check in</button>
          </span>
        </div>
        <div class="float-left mt-1" v-if="person.checked_in_count > 0">
          <button type="button" class="btn btn-secondary btn-sm" @click="removeCheckIn()">Remove check in</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkInAllGuests, checkInPerson, removeCheckIn} from "@/api";

export default {
  name: "PersonCheckInItem",
  props: ['person', 'unit_names', 'group_names', 'wristbands', 'eventId', 'openedItemId', 'badges'],
  methods: {
    findWristbandName(wristbandId) {
      if (this.wristbands === undefined || this.wristbands === null || this.wristbands.length === 0)
        return ''
      else {
        let wristband = this.wristbands.find(w => {
          return w.id == wristbandId
        })
        if (wristband != undefined) {
          return wristband.name
        }
      }
    },
    findBadgeName(badgeId) {
      if (this.badges === undefined || this.badges === null || this.badges.length === 0)
        return ''
      else {
        let badge = this.badges.find(b => {
          return b.id == badgeId
        })
        if (badge != undefined) {
          return badge.name
        }
      }
    },
    async checkIn() {
      this.person.checked_in = true
      const result = checkInPerson(this.eventId, {'person_id': this.person.id})

      this.person.checked_in_count = this.person.checked_in_count + 1
    },
    async removeCheckIn() {
      this.person.checked_in = false
      const result = await removeCheckIn(this.eventId, {'person_id': this.person.id})

      this.person.checked_in_count = this.person.checked_in_count - 1
    },
    async checkInAll() {
      const result = await checkInAllGuests(this.eventId, {'person_id': this.person.id})
      this.person.checked_in_count = this.person.guest_count  += 1
    },
    openDetails() {
      this.$emit('opened', this.person.id)
    }
  },
  mounted() {
  },
}
</script>
