import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Events from '../views/Events.vue'
import AddEvent from '../views/AddEvent.vue'
import Event from '../views/Event.vue'
import AccessSystem from '../views/AccessSystem.vue'
import EventUnits from '../views/EventUnits.vue'
import EventUnit from '../views/EventUnit.vue'
import EventPeople from '../views/EventPeople.vue'
import EventGroup from '../views/EventGroup.vue'
import UserHome from '../views/UserHome.vue'
import UserDashboard from "@/views/UserDashboard";
import CheckIn from "@/views/CheckIn";
import EventUsers from "@/views/EventUsers";
import EventAllData from "@/views/EventAllData";
import MenuTest from "@/views/MenuTest";


Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: UserDashboard },
  { path: '/register', name: 'Register', component: Register },
  { path: '/login', name: 'Login', component: Login },
  { path: '/events', name: 'Events', component: Events },
  { path: '/user-home', name: 'UserHome', component: UserHome },
  { path: '/add-event', name: 'AddEvent', component: AddEvent },
  { path: '/event/:id', name: 'Event', component: Event, props: true },
  { path: '/event/:eventId/access', name: 'AccessSystem', component: AccessSystem, props: true },
  { path: '/event/:eventId/all_data', name: 'EventAllData', component: EventAllData, props: true },
  { path: '/event/:eventId/check_in', name: 'CheckIn', component: CheckIn, props: true },
  { path: '/event/:eventId/users', name: 'EventUsers', component: EventUsers, props: true },
  { path: '/event/:eventId/units', name: 'EventUnits', component: EventUnits, props: true },
  { path: '/event/:eventId/people', name: 'EventPeople', component: EventPeople, props: true },
  { path: '/event/:eventId/unit/:unitId', name: 'EventUnit', component: EventUnit, props: true },
  { path: '/event/:eventId/unit/:unitId/group/:groupId', name: 'EventGroup', component: EventGroup, props: true },
  { path: '/event/:eventId/menutest', name: 'MenuTest', component: MenuTest, props: true}
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getUser();

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})

export default router
