<template>
  <div class="card mt-3 card-body">
    <div class="row">
      <div class="col-3">
        <div class="item-title">
          Name
        </div>
        <div>
          {{ person.name }}
        </div>
      </div>
      <div class="col-2">
        <div class="item-title">
          Unit
        </div>
        <div>
          {{ unit_names[person.unit_id] }}
        </div>
      </div>
      <div class="col-2">
        <div v-if="person.group_id != null" class="item-title">
          Group
        </div>
        <div v-if="person.group_id != null">
          {{ group_names[person.group_id] }}
        </div>
      </div>
      <div class="col-2">
        <div class="item-title">
          Profile
        </div>
        <div class="d-flex flex-row">
          <select type="select" v-model="person.profile_id">
            <option value="null" disabled>Profile ...</option>
            <option v-for="profile in profiles" v-bind:key="profile.id"
                    v-bind:value="profile.id">{{ profile.name }}
            </option>
          </select>
          <button class="ms-2 btn btn-secondary" @click="saveProfile(person)">Set</button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <div class="item-title">&nbsp;</div>
        <div>
          <button class="btn btn-secondary" @click="changeName(perosn)">Change name</button>
        </div>
      </div>
      <div class="col-3">
        <div class="item-title">
          Comments
        </div>
        <div>
          {{ person.comments }}
        </div>
      </div>
      <!--
    </div>
  </div>
</template>
<div class="col-3">
<span v-if="person.approved === null">Unkown </span>
<span v-else>{{person.approved ? 'Yes' : 'No'}}</span>
<span v-if="!person.approved">
  <button @click="approve(person)">Approve</button>
</span>
<span v-else>
  <button @click="removeApproval(person)">Remove approval</button>
</span>
</div>
-->
    </div>
  </div>
</template>
      <script>
        import axios from 'axios'
        import store from "@/store";
        import {approvePerson, removeEventPerson, removePersonApproval, updatePerson} from "@/api";

        export default {
          name: "PersonDataItem",
          props: ['person', 'unit_names', 'group_names', 'profiles', 'eventId'],
          methods: {
            async onRemove() {
              if (window.confirm("Delete this person from the event?")) {
                await removeEventPerson(this.eventId, {'person_id': this.person.id})
                this.$emit('removed', this.person.id)
              }
            },
            async saveProfile() {
              await updatePerson(this.eventId, this.person.id, {
                'id': this.person.id,
                'profile_id': this.person.profile_id
              })
            },
            changeName(person) {
              // display modal for name change
            },
            async approve(person) {
              approvePerson(this.eventId, person.id)
              person.approved = true
            },
            async removeApproval(person) {
              removePersonApproval(this.eventId, person.id)
              person.approved = false
            },
          },
        }
      </script>

      <style scoped>

      </style>
