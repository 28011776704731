<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link :to="{name: 'Event', params: { eventId: event.id }}">
          {{event.name}}
        </router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{currentPageName}}</li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: ['event', 'currentPageName', 'pages']
}
</script>

<style scoped>

</style>
