<template>
  <div>
    <h5 class="mt-3">People</h5>
    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th scope="col" v-for="field of addPersonFields" v-bind:key="field.id">
          {{ field.label }}
        </th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="person of people" v-bind:key="person.id">
        <td>{{ person.name }}</td>
        <td v-for="field of person.fields" v-bind:key="field.id">
          {{ field.value }}
        </td>
        <td>{{ person.comments }}</td>
          <td><span v-if="person.plus_ones > 0">+{{ person.plus_ones }}</span></td>
      </tr>
      </tbody>
    </table>
    <h5 class="mt-3">Add new</h5>
    <div class="row mt-1">
      <div class="col-2">
        <label for="name">Name</label>
      </div>
      <div class="col-6">
        <input type="text" id="name" v-model="personName"/>
      </div>
    </div>
    <div v-for="(field, index) of addPersonFields" v-bind:key="field.id">
      <div class="row mt-1">
        <div class="col-2">
          <label :for="`${field.name}_${field.id}`">{{ field.label }}</label>
        </div>
        <div class="col-6">
          <input type="text" :id="`${field.name}_${field.id}`" v-model="addPersonFields[index].value"/>
        </div>
      </div>
    </div>
    <button @click="addPerson()">Add person</button>
    <MassAddPeople v-on:addedPeople="addedPeople" :eventId="eventId" :unitId="unitId" :groupId="groupId"/>
  </div>
</template>

<script>
import MassAddPeople from "@/components/MassAddPeople";
import {addGroupPerson, addUnitPerson} from "@/api";

export default {
  name: "UnitGroupPeople",
  props: ['eventId', 'unitId', 'groupId', 'people'],
  components: {
    MassAddPeople
  },
  data() {
    return {
      personName: '',
      addPersonFields: []
    }
  },
  methods: {
    addedPeople(added) {
      this.$emit('addedPeople', added)
    },
    async addPerson() {
      let addedPerson = null
      if (this.groupId !== null) {
        addedPerson = await addGroupPerson(this.eventId, this.unitId, this.groupId, {name: this.personName, fields: this.personFields})
      } else {
         addedPerson = await addUnitPerson(this.eventId, this.unitId, {name: this.personName, fields: this.personFields})
      }
      this.personName = ''

      this.$emit('addedPeople', [addedPerson])
    }
  },
}
</script>
