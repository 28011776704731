<template>
  <div>
    <h1>Access system</h1>
    <ModalArea ref="openedAreaModal" v-on:deleted="removeObject"/>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="item-title">
              Areas
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" class="form-control pe-0" v-model="areaName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary btn-small" @click="addArea">Add area</button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="item-title">
              Areas
            </div>
            <button v-for="area in accessSystem.areas" :key="area.id"
                    class="me-2 btn btn-secondary pill-lg rounded-pill"
                    @click="openArea(area)">
              <Icon iconClass="me-2" name="pen"/>
              {{ area.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="item-title">
              Times
            </div>
            <div>
              <div class="row">
                <div class="col-auto">
                  <input type="text" class="form-control pe-0" v-model="timeName"/>
                </div>
                <div class="col-auto p-0">
                  <button class="btn btn-primary" @click="addTime">Add time</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="item-title">
              Times
            </div>
            <div>
              <button v-for="time in accessSystem.times" :key="time.id" class="me-2 btn btn-secondary rounded-pill"
                      @click="openTime(time)">
                <Icon iconClass="me-2" name="pen"/>
                {{ time.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="item-title">
              Profiles
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" class="form-control pe-0" v-model="profileName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary" @click="addProfile">Add profile</button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="item-title">
              Profiles
            </div>
            <div>
              <button v-for="profile in accessSystem.profiles" :key="profile.id"
                      class="me-2 btn btn-secondary rounded-pill">
                <Icon iconClass="me-2" name="pen"/>
                {{ profile.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="item-title">
              Wristbands
            </div>
            <div>
              <div class="row">
                <div class="col-auto">
                  <input type="text" class="form-control pe-0" v-model="wristbandName"/>
                </div>
                <div class="col-auto p-0">
                  <button class="btn btn-primary" @click="addWristband">Add wristband</button>
                </div>
              </div>

            </div>
          </div>
          <div class="col-6">
            <div class="item-title">
              Wristbands
            </div>
            <div>
              <button v-for="wristband in accessSystem.wristbands" :key="wristband.id"
                      class="me-2 btn btn-secondary rounded-pill">
                {{ wristband.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="item-title">
              Badges
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" class="form-control pe-0" v-model="badgeName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary btn-small" @click="addBadge">Add badge</button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="item-title">
              Badges
            </div>
            <div>
              <button v-for="badge in accessSystem.badges" :key="badge.id" class="btn btn-secondary rounded-pill">
                {{ badge.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <div class="item-title">
              Upload media
            </div>
            <div>
              <input type="file" ref="file" @input="pickFile" accept="image/*" :name="uploadFieldName"/>
            </div>
          </div>
          <div class="col-2">
            <div class="item-title">
              Choose type
            </div>
            <div>
                <span>
                  <input class="form-check-input" id="typeWristband" type="radio" v-on:change="changeType('wristband')"
                         v-model="uploadType" value="wristband"/>
                  <label class="form-check-label ms-2" for="typeWristband">Wristband</label>
                </span>
              <span class="ms-3">
                  <input class="form-check-input" id="typeBadge" type="radio" v-on:change="changeType('badge')"
                         v-model="uploadType" value="badge"/>
                  <label class="form-check-label ms-2" for="typeBadge">Badge</label>
                </span>
            </div>
          </div>
          <div class="col-3">
            <div class="item-title">
              Choose badge/wristband
            </div>
            <div>
              <select v-model="uploadedTypeId">
                <option v-for="o in options" :value="o.id" v-bind:key="o.id">{{ o.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="item-title">&nbsp;</div>
            <div>
              <button class="btn btn-secondary" v-on:click="uploadPicture()">Upload media</button>
            </div>
          </div>
        </div>

        <div class="col-6 mt-3">
          <div class="item-title">
            Wristbands
          </div>
          <div class="card" v-for="w in wristbandsWithPicture" v-bind:key="w.id">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <img class="img-fluid" :src="w.picture_url"/>
                </div>
              </div>
              <div class="col-2">
                <div class="item-title">
                  Name
                </div>
                <div>
                  {{ w.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 mt-3">
          <div class="item-title">
            Badges
          </div>
          <div class="card" v-for="w in badgesWithPicture" v-bind:key="w.id">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <img class="img-fluid" :src="w.picture_url"/>
                </div>
              </div>
              <div class="col-2">
                <div class="item-title">
                  Name
                </div>
                <div>
                  {{ w.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="mt-5">Access matrix</h3>
    <table class="table mt-3">
      <tbody>
      <tr>
        <td>
        </td>
        <td class="text-center" v-for="area in accessSystem.areas" :key="area.id">{{ area.name }}</td>
        <td class="text-center">Times</td>
        <td class="text-center">Wristband</td>
        <td class="text-center">Badge</td>
      </tr>

      <tr v-for="profile in accessSystem.profiles" :key="profile.id">
        <td class="text-center">{{ profile.name }}</td>
        <td class="text-center bg-light" v-for="area in accessSystem.areas" :key="area.id">
          <input type="checkbox" v-model="profile.areas" :value="area.id"
                 @click="updateProfileArea(profile.id, area.id)"/>
        </td>
        <td class="text-center bg-light">
          <select v-model="profile.timeId">
            <option value="" disabled>Select a time</option>
            <option v-for="time in accessSystem.times" :value="time.id" :key="time.id">{{ time.name }}</option>
          </select>
        </td>
        <td class="text-center bg-light">
          <select v-model="profile.wristbandId">
            <option value="" disabled>Select a wristband</option>
            <option v-for="wristband in accessSystem.wristbands" :value="wristband.id" :key="wristband.id">
              {{ wristband.name }}
            </option>
          </select>
        </td>
        <td class="text-center bg-light">
          <select v-model="profile.badgeId">
            <option value="" disabled>Select a badge</option>
            <option v-for="badge in accessSystem.badges" :value="badge.id" :key="badge.id">{{ badge.name }}</option>
          </select>
        </td>
      </tr>
      </tbody>
    </table>
    <button class="button btn-primary" @click="saveAccess()">Save</button>
  </div>
</template>
<script>
import ModalArea from "@/components/ModalArea";
import axios from 'axios'
import store from '../store'
import {addEventArea, addEventBadge, addEventProfile, addEventTime, addEventWristband, saveAccessSystem} from "@/api";
import Icon from "@/components/Icon";

export default {
  name: 'AccessSystemEditing',
  components: {
    ModalArea, Icon
  },
  data() {
    return {
      openedArea: null,

      areaName: '',
      timeName: '',
      profileName: '',
      wristbandName: '',
      badgeName: '',

      uploadFieldName: '',
      uploadType: null,
      uploadWristbandId: null,
      uploadBadgeId: null,
      uploadedTypeId: null,
      options: [],
    }
  },
  props: [
    'accessSystem', 'eventId'
  ],
  methods: {
    async uploadPicture() {
      const formData = new FormData();
      formData.append('file', this.$refs.file.files[0])
      formData.append('uploadType', this.uploadType)
      formData.append('uploadedTypeId', this.uploadedTypeId)
      const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + store.jwt(),
        "Accept": "application/json"
      };
      const result = await axios.post(`/api/event/${this.eventId}/upload_file`, formData, {headers})
      if (result.status === 200) {
        if (this.uploadType === 'wristband') {
          let w = this.accessSystem.wristbands.find(w => w.id === this.uploadedTypeId)
          w.picture_url = result.data.picture_url
        }
      }
    },
    pickFile() {
      let input = this.$refs.file
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    changeType(typeName) {
      this.uploadType = typeName
      if (typeName === 'wristband')
        this.options = this.accessSystem.wristbands
      else
        this.options = this.accessSystem.badges
    },
    async addArea() {
      if (this.areaName.length == 0)
        return

      const result = await addEventArea(this.eventId, {name: this.areaName})
      this.accessSystem.areas.push(result)
      this.areaName = ''
    },
    async addTime() {
      if (this.timeName.length == 0)
        return

      const result = await addEventTime(this.eventId, {name: this.timeName})
      this.accessSystem.times.push(result)
      this.timeName = ''
    },
    async addProfile() {
      if (this.profileName.length == 0)
        return

      const result = await addEventProfile(this.eventId, {name: this.profileName})
      this.accessSystem.profiles.push(result)
      this.profileName = ''
    },
    async addWristband() {
      if (this.wristbandName.length == 0)
        return

      const result = await addEventWristband(this.eventId, {name: this.wristbandName})
      this.accessSystem.wristbands.push(result)
      this.wristbandName = ''
    },
    async addBadge() {
      if (this.badgeName.length == 0)
        return

      const result = addEventBadge(this.eventId, {name: this.badgeName})
      this.accessSystem.badges.push(result)
      this.badgeName = ''
    },
    updateProfileArea(profile, area) {
    },
    async saveAccess() {
      const result = await saveAccessSystem(this.accessSystem.event.id, {profiles: this.accessSystem.profiles})
      this.$router.go()
    },
    openArea(area) {
      this.$refs.openedAreaModal.present(area, "area")
    },
    openTime(time) {
      this.$refs.openedAreaModal.present(time, "time")
    },
    removeObject(area, objectType) {
      if (objectType === 'area') {
        this.areas = this.accessSystem.areas.filter(area => area !== area)
      } else if (objectType === 'time') {
        this.times = this.accessSystem.times.filter(area => area !== area)
      }
    }
  },
  computed: {
    wristbandsWithPicture() {
      return this.accessSystem.wristbands.filter(w => w.picture_url !== null)
    },
    badgesWithPicture() {
      return this.accessSystem.badges.filter(w => w.picture_url !== null)
    }
  }
}
</script>
