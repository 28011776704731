<template>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div class="card-body p-5 text-center">
            <form v-on:submit.prevent="login">
              <h3 class="mb-5">Log in</h3>
              <div class="form-outline mb-4">
                <input type="email" id="email" v-model="email" class="form-control form-control-lg" />
                <label class="form-label" for="email">Email</label>
              </div>
              <div class="form-outline mb-4">
                <input type="password" id="password" v-model="password" class="form-control form-control-lg" />
                <label class="form-label" for="password">Password</label>
              </div>
              <button class="btn btn-primary btn-block" type="submit">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'

export default {
  name: 'Register',
  components: {},
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      const result = await axios.post('/api/auth', { username: this.email, password: this.password })
      store.saveJwt(result.data.access_token)
      this.$router.replace({ name: 'Events' })
    }
  }
}
</script>
