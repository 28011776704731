import Vue from 'vue'

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

const JWT = 'JWT'
const EVENT = 'EVENT'

const store = {
  saveJwt (jwt) {
    jwt && sessionStorage.setItem(JWT, jwt)
    const token = jwt && parseJwt(jwt)
    const user = token && token.user
    user && sessionStorage.setItem('USER', JSON.stringify(user))
  },
  jwt() {
    return sessionStorage.getItem(JWT);
  },
  getUser() {
    return sessionStorage.getItem('USER')
  },
  parseJwt(token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
  },
  logOut() {
    sessionStorage.clear()
  },
  setEvent(event) {
    sessionStorage.setItem(EVENT, event)
  },
  getEvent() {
    sessionStorage.getItem(EVENT)
  }
}

export default store

window.api = {
  store,
}
