<template>
  <div class="card">
    <div class="card-body">
      <router-link :to="{name: 'Event', params: { id: event.id }}"><strong>{{event.name}}</strong></router-link>
      <p>{{event.start_time}} - {{event.end_time}}</p>
      <p>{{event.description}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventItem',
  props: [
    'event'
  ]
}
</script>
