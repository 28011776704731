<template>
  <div id="" class="container" v-if="event">
    <Navbar :event="event" :eventId="eventId"></Navbar>
    <div class="d-flex flex-row mt-3">
      <div class="flex-grow-1">
        <Breadcrumbs :event="event" currentPageName="Check in"/>
      </div>
      <div class="align-self-end">
        <h3>{{ event.name }}</h3>
      </div>
    </div>
    <h1>Units</h1>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div>&nbsp;</div>
            <div>
              <input type="text" v-model="unitName" class="mx-2 mb-2"/>
              <button class="btn btn-primary btn-small" @click="addUnit">Add unit</button>
            </div>
          </div>
          <div class="col-6 ms-3">
            <div class="item-title">
              Units
            </div>
            <ul class="list-group list-group-horizontal">
              <li v-for="unit in units" :key="unit.id" class="list-group-item rounded-pill bg-secondary text-white">
                <router-link class="link-dark text-decoration-none" :to="{name: 'EventUnit', params: { eventId: eventId, unitId: unit.id } }">
                  {{ unit.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import {addEventUnit, getEvent, getEventUnits} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: 'EventUnits',
  props: ['eventId'],
  components: {
    Navbar,
    Breadcrumbs
  },
  data() {
    return {
      units: null,
      unitName: '',
      event: null
    }
  },
  methods: {
    async addUnit() {
      const unit = await addEventUnit(this.eventId, {name: this.unitName})
      this.units.push(unit)
      this.unitName = ''
    },
  },
  async mounted() {
    this.units = await getEventUnits(this.eventId)

    const result = await getEvent(this.eventId)
    this.event = result.event
  }
}
</script>
