<template>
  <div class="container py-5 h-100">
    <router-link :to="{name: 'Login'}" class="nav-link" href="#">
      <span class="nav-text">Log in</span>
    </router-link>
    or
    <router-link :to="{name: 'Register'}" class="nav-link" href="#">
      <span class="nav-text">Register</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    if (store.isLoggedIn()) {
      this.$router.push('/dashboard')
    }
  },
}
</script>
