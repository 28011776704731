<template>
  <div>
    <div class="mt-3">
      <h5>Add multiple guests</h5>
      <textarea v-model="peopleText" class="form-control" id="people" rows="10"></textarea>
    </div>
    <button type="button" @click="add()" class="btn btn-primary mt-3">Add</button>
  </div>
</template>

<script>
import axios from 'axios'
import {addEventMassGuests} from "@/api";

export default {
  name: "MassAddPeople",
  props: ['eventId', 'unitId', 'groupId'],
  data() {
    return {
      peopleText: '',
    }
  },
  methods: {
    async add() {
      const addedPeople = await addEventMassGuests(this.eventId, this.unitId, this.groupId, this.peopleText)
      this.$emit("addedPeople", addedPeople)
    }
  },
}
</script>
