<template>
  <div class="container">
    <Navbar></Navbar>
    <div class="pt-3 mb-4">Add a new event</div>
    <div class="card elevation-16 mb-3">
      <div class="card-body">
      <form v-on:submit.prevent="addEvent">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <label>Name
                <input v-model="name" type="name" />
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label>Description
                <textarea v-model="description" rows="5" cols="60"/>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label>Start time
                <input type="datetime-local" v-model="start_time" rows="5" cols="60"/>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label>End time
                <input type="datetime-local" v-model="end_time" rows="5" cols="60"/>
                </label>
              </div>
            </div>
            <div class="mt-5">
              <input type="submit" value="Add event" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import store from "@/store";

export default {
  name: 'AddEvent',
  components: {Navbar},
  data () {
    return {
      end_time: '',
      start_time: '',
      name: '',
      description: ''
    }
  },
  methods: {
    async addEvent () {
      const headers = {
        "Authorization": "Bearer " + store.jwt()
      }
      await axios.post('/api/events', { name: this.name, description: this.description, start: this.start_time, end: this.end_time },
        { headers })

      this.$router.replace({ name: 'Events' })
    }
  }
}
</script>
