<template>
  <div id="" class="container check-in">
    <Navbar :event="event" :eventId="eventId"/>
    <div v-if="event" class="d-flex flex-row mt-3">
      <div class="flex-grow-1">
        <Breadcrumbs :event="event" currentPageName="People"/>
      </div>
      <div class="align-self-end">
        <h3>{{ event.name }}</h3>
      </div>
    </div>
    <div v-if="event">
      <h3>People</h3>
      <div class="row mt-3">
        <div class="col-8">
          <div class="item-title">
            Add unit
          </div>
          <div class="row">
            <div class="col-auto">
              <input type="text" class="form-control pe-0" v-model="unitName"/>
            </div>
            <div class="col-auto p-0">
              <button class="btn btn-primary btn-small" @click="addUnit">Add unit</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <ul class="mt-2 list-group" v-for="unit in units" v-bind:key="unit.id">
          <button @click="selectUnit(unit.id)"
                  v-bind:class="[selectedUnit === unit.id ? 'btn-primary' : 'btn-outline-primary', 'btn btn-sm']">
            {{ unit.name }}
          </button>
          <span v-if="selectedUnit === unit.id">
            <router-link :to="{name: 'EventUnit', params: { eventId: event.id, unitId: unit.id }}" class="nav-link">
              Edit unit
            </router-link>
            <li class="list-group-item" v-for="group in unit.groups" v-bind:key="group.id">
              <ul class="mt-2 list-group">
                <li @click="selectGroup(group.id)"
                    v-bind:class="[selectedGroup === group.id ? 'active' : '', 'list-group-item']">
                {{ group.name }}
                </li>
                  <span v-if="selectedGroup === group.id">
                    <router-link
                      :to="{name: 'EventGroup', params: { eventId: event.id, unitId: unit.id, groupId: group.id }}"
                      class="nav-link">
                      Edit group
                    </router-link>
                  </span>
              </ul>
            </li>
          </span>
        </ul>
      </div>

      <PeopleData :people="people" :event="event" :selectedUnit="selectedUnit" :selectedGroup="selectedGroup"
                  :unit_names="unit_names" :group_names="group_names" :profiles="profiles"
                  :unitId="null"
                  v-on:updatedProfile="updatedProfile"
                  v-on:updatedPerson="updatedPerson" />
    </div>
  </div>
</template>

<script>
import {addEventUnit, getEvent, getEventPeople} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import Navbar from "@/components/Navbar";
import PeopleData from "@/components/PeopleData";

export default {
  name: "EventPeople",
  props: ['eventId', 'unitId', 'groupId'],
  components: {Breadcrumbs, Navbar, PeopleData},
  data() {
    return {
      unitName: '',
      people: [],
      units: [],
      profiles: [],
      group_names: [],
      unit_names: [],
      selectedGroup: null,
      selectedUnit: null,
      event: null,
    }
  },
  async mounted() {
    const people = await getEventPeople(this.eventId)
    this.people = people.people
    this.fields = people.fields

    this.checkedPeople = []

    const result = await getEvent(this.eventId)
    this.event = result.event
    this.units = result.units
    this.group_names = result.group_names
    this.unit_names = result.unit_names
    this.wristbands = result.wristbands
    this.badges = result.badges
    this.profiles = result.profiles
  },
  methods: {
    async addUnit() {
      const unit = await addEventUnit(this.eventId, {name: this.unitName})
      this.units.push(unit)
      this.unitName = ''
    },
    selectGroup(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
      this.$emit('selectedGroup', group)
    },
    updatedProfile(profileId, updatedPeople) {
      this.people.forEach(p => {
        if (updatedPeople.includes(p.id)) {
          p.profile_id = profileId
        }
      })
    },
    updatedPerson(personId, data) {
      let person = this.people.find(p => p.id === personId)
      person.name = data.name
      person.comments = data.comments
      person.guest_count = data.guest_count
    },
    selectUnit(unit) {
      if (this.selectedUnit === unit) {
        this.selectedUnit = null
      } else {
        this.selectedUnit = unit
        this.selectedGroup = null
      }
      this.$emit('selectedUnit', unit)
    },
  },
}
</script>

